<template>
  <div :class="['relative', $style.field]">
    <div>
      <span
        :class="[
          $style.checkbox,
          {
            [$style.inputWhite]: style === 'white',
            [$style.inputPurple]: style === 'purple',
            [$style.isChecked]: modelValue,
            [$style.isError]: isError,
            [$style.isDisabled]: isDisabled
          },
          classCheckbox
        ]"
        aria-hidden="true"
      >
        <span :class="$style.check">
          <Icon
            name="checkbox-general"
            class="relative !flex h-5px w-5px"
            :color="modelValue ? 'white' : 'black'"
          />
        </span>
      </span>
      <input
        ref="input"
        v-uid
        v-bind="$attrs"
        class="sr-only"
        :checked="modelValue"
        :disabled="isDisabled"
        type="checkbox"
        @input="updateValue"
      />
      <label
        :for="input?.id"
        :class="[
          'min-h-[var(--checkbox-size)]] font-mono relative inline-flex w-full pl-[calc(var(--checkbox-size)+12px)] text-[16px] leading-[var(--checkbox-size)]',
          isLabelVisible ? 'cursor-pointer' : 'sr-only',
          {
            isError,
            isDisabled
          },
          classLabel
        ]"
      >
        <span v-if="label" v-html="label" />
        <slot v-else />
        <span v-if="isRequired" class="pl-px text-orange">*</span>
      </label>
    </div>
    <Transition name="label">
      <div v-if="isError && errorMessage" class="mt-2px flex w-full text-[14px] text-red md:mt-1px">
        {{ errorMessage }}
      </div>
    </Transition>
  </div>
</template>

<script lang="ts">
import { InputStyles } from './FormInput.vue'

export default {
  inheritAttrs: false
}
</script>

<script setup lang="ts">
interface InputProps {
  label?: string
  isLabelVisible?: boolean
  isRequired?: boolean
  modelValue: boolean
  isDisabled?: boolean
  isError?: boolean
  errorMessage?: string
  classLabel?: string
  classCheckbox?: string
  style?: InputStyles | 'white'
}

withDefaults(defineProps<InputProps>(), {
  isLabelVisible: true,
  modelValue: false,
  isDisabled: false,
  isError: false
})

const emit = defineEmits(['update:modelValue'])

const updateValue = (e: Event) => {
  emit('update:modelValue', (e.target as HTMLInputElement).checked)
}

const input = ref<null | HTMLElement>(null)
</script>

<style lang="postcss" module>
.field {
  --checkbox-size: 24px;
  --checkbox-background-color: var(--input-background-color);
  --checkbox-border-color: var(--input-border-color);
  --checkbox-color: var(--input-text-color);
}

.checkbox {
  position: absolute;
  left: 0;
  top: 0;
  width: var(--checkbox-size);
  height: var(--checkbox-size);
  background: var(--checkbox-background-color);
  border-radius: 8px;
  border: 1px solid var(--checkbox-border-color);

  @apply transition-colors duration-300;
}

.check {
  --check-height: 20px;
  --check-width: 0px;

  backface-visibility: hidden;
  overflow: hidden;
  width: var(--check-width);
  height: var(--check-height);
  color: var(--checkbox-color);
  display: block;
  position: absolute;
  left: 1px;
  top: 50%;
  transform: translateY(-50%);

  display: block;
}

.inputWhite {
  --checkbox-background-color: white;
}

/*  States  */

.isChecked {
  --checkbox-background-color: theme(colors.black);
  --checkbox-color: theme(colors.white);

  .check {
    --check-width: 20px;
    transition: 150ms ease-out;
    transition-delay: 200ms;
  }
}

.isDisabled {
  --checkbox-background-color: theme('colors.neutral.100');
  --checkbox-color: theme('colors.neutral.100');

  ~ label {
    cursor: default;
  }
}

.isError {
  --checkbox-border-color: theme('colors.red');
}

.inputPurple {
  --checkbox-placeholder-color: #625489;
  --checkbox-background-color: #362d4f;
  --checkbox-text-color: theme('colors.white');
  --checkbox-color: #625489;

  &:not(.isError) {
    --checkbox-border-color: #362d4f;
  }
}
</style>
